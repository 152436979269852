import React from "react"
import { graphql, PageProps } from "gatsby";
import { Box, Typography} from "@mui/material";
import Layout from "../components/Layout"
import bricks from '../images/bricks.svg'
import { StaticImage } from "gatsby-plugin-image"
import { MetaData } from "../components/common/meta";

import '../styles/style.css';


const postStyle = {
  maxWidth:'1000px', 
  mx: 'auto',
  py: 15,
}


export interface PageInterface{
  title: string,
  slug: string,
  html: string,

}

export interface ghostPageInterface{
  ghostPage: PageInterface
}

const page = ({ data, location}:{ data:ghostPageInterface, location:PageProps}) => { 
  const page = data.ghostPage;
  return (
    <Layout>
      
      <MetaData data={data} location={location} type="website" />

      <Box component="img" src={bricks} sx={{
                  position:'absolute',
                  left: '50%',
                  top: '0',
                  transform:'translateX(-50%)',
                  zIndex: -1,
                  pointerEvents:'none'
                }}/>
        <Box  component="div" className="postStyle" sx={postStyle}>
          <Box component="div" sx={{maxWidth: 750, mx:'auto',mt: 4, mb:6, textAlign: 'center'}}>
            <Typography  variant="h1">{page.title}</Typography>
          </Box>
            <Box component="div" sx={{
              backgroundColor: '#091A3D',
              border: '1px solid #4B608D', 
              px:{xs:3, md:5} ,
              py:{xs:3, md:6} , 
            }}>
              <section dangerouslySetInnerHTML={{ __html: page.html }} />
            </Box>
        </Box>
        <StaticImage
          src="../images/index/lines.svg"
          alt=""
          placeholder="blurred"
          style={{
              position:'absolute',
              right: '0',
              bottom: '80px',
              zIndex: -1,
              pointerEvents:'none'
          }}
        />
    </Layout>
  )
}

export default page

export const postQuery = graphql`
  query($slug: String!) {
    ghostPage(slug: { eq: $slug }) {
      ...GhostPageFields
    }
  }
`